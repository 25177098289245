import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { set } from 'lodash';
import PropTypes from 'prop-types';

import Filter from './Filter';
import KnowMoreModal from './KnowMoreModal';
import { getFunnelHotspotTransactions } from '../../../../../api/analytics';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/InfoIcon2.svg';
import {
  ANALYTICS_API_ENDPOINTS,
  AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME
} from '../../../../../constants';
import { ANALYTICS_TRACKING_EVENT_NAMES } from '../../../../../constants/tracking';
import storeAnalyticsMetadata from '../../../../../utils/storeAnalyticsUsageMetadata';
import Table from '../../../../Shared/Table';

function Body({ currentStatusHotspots, activeHotspot, filters, statusSelected }) {
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const displayKnowMoreModal = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const hideKnowMoreModal = (event) => {
    event.stopPropagation();
    setShowModal(false);
  };

  const onFiltersApply = async (selectedHotspot) => {
    const startTime = performance.now();
    const eventData = {
      APIEndpoint: ANALYTICS_API_ENDPOINTS.BUSINESS_METRICS_HOTSPOT_TRANSACTIONS,
      userClientId: filters.clientId,
      metaData: {
        filters
      },
      timestamp: new Date().toISOString()
    };
    setIsLoading(true);
    try {
      setTransactionData([]);
      const hotspotData = currentStatusHotspots.find(
        (hotspot) => hotspot.label === selectedHotspot
      );
      const data = await getFunnelHotspotTransactions({
        ...filters,
        ...hotspotData,
        statusSelected
      });
      if (data.apiError) {
        setTransactionData([]);
        setIsLoading(false);
        return;
      }
      setTransactionData(data);
      set(eventData, 'statusCode', 200);
    } catch (error) {
      Sentry.captureException(error);
      setTransactionData([]);
      set(eventData, 'statusCode', 500);
    }
    const endTime = performance.now();
    const processingTime = endTime - startTime;
    set(eventData, 'processingTime', processingTime);
    storeAnalyticsMetadata(
      ANALYTICS_TRACKING_EVENT_NAMES.BUSINESS_METRICS_HOTSPOTS_API_CALL,
      '',
      AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME,
      '',
      eventData
    );
    setIsLoading(false);
  };

  useEffect(() => {
    onFiltersApply(activeHotspot);
    setShowModal(false);
  }, [activeHotspot]);

  useEffect(() => {
    setShowModal(false);
  }, []);

  return (
    <div className="hotspot__side_panel__body__container">
      <KnowMoreModal showModal={showModal} onHide={hideKnowMoreModal} />
      <Filter
        currentStatusHotspots={currentStatusHotspots}
        activeHotspot={activeHotspot}
        onFiltersApply={onFiltersApply}
      />
      <div className="hotspot__side_panel__body__info_container">
        <p>
          <InfoIcon className="hotspot__side_panel__body__info_icon" />
          Application status and flags within an application might have changed in the latest
          attempt
          <span onClick={displayKnowMoreModal} aria-hidden>
            Know more
          </span>
        </p>
      </div>
      <div className="hotspot__side_panel__transactions_table_container">
        <Table isLoading={isLoading} transactionData={transactionData} filters={filters} />
      </div>
    </div>
  );
}

Body.propTypes = {
  currentStatusHotspots: PropTypes.array.isRequired,
  activeHotspot: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  statusSelected: PropTypes.string.isRequired
};

export default Body;
