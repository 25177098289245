import React, { useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';
import './BusinessMetrics.css';
import { set } from 'lodash';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { PermissionWrapper } from 'storybook-ui-components';

import getAnalytics, { getFlagAnalytics } from '../../api/analytics';
import { getWorkflowV2 } from '../../api/workflow';
import FunnelAnalyticsComparison from '../../Components/PageComponents/Home/Comparison';
import FunnelAnalytics from '../../Components/PageComponents/Home/FunnelAnalytics';
import { convertSecondsToMinutesSeconds } from '../../Components/PageComponents/Home/StepwiseAnalytics/utils';
import AnalyticsFilters from '../../Components/Shared/Filters';
import Toggle from '../../Components/Shared/Toggle';
import {
  ANALYTICS_API_ENDPOINTS,
  AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME,
  SANKEY_LABELS
} from '../../constants';
import {
  ANALYTICS_TRACKING_EVENT_NAMES,
  ANALYTICS_TRACKING_SCREEN_NAMES
} from '../../constants/tracking';
import CustomConnect from '../../containers/HOC/CustomConnect';
import useGetUserPermissions from '../../Permissions/hooks';
import getPermission from '../../Permissions/mapping';
import {
  calculateKeyMetrics,
  computePercentagesForFunnel,
  generateFlagsData,
  getSankeyLinks,
  getSankeyWeights
} from '../../utils/businessMetrics';
import { isAPIError, returnDefaultDateRange } from '../../utils/helpers';
import SankeyChart from '../../utils/sankey';
import storeAnalyticsMetadata from '../../utils/storeAnalyticsUsageMetadata';

function BusinessMetrics() {
  const [funnelFirstLoad, setFunnelFirstLoad] = useState(true);
  const [comparisonsFirstLoad, setComparisonsFirstLoad] = useState(true);
  const [noData, setNoData] = useState(true);
  const [keyMetrics, setKeyMetrics] = useState({
    conversionRate: '-',
    autoApprovalRate: '-',
    automationRate: '-',
    medianTimeSpent: '-'
  });
  const [analyticsData, setAnalyticsData] = useState(null);
  const [manualReviewFlagsData, setManualReviewFlagsData] = useState([]);
  const [autoRejectedFlagsData, setAutoRejectedFlagsData] = useState([]);
  const [errorFlagsData, setErrorFlagsData] = useState([]);
  const [loadingSankey, setLoadingSankey] = useState(false);
  const [loadingFlags, setLoadingFlags] = useState(false);
  const [loadingComparisons, setLoadingComparisons] = useState(false);
  const [comparisonMode, setComparisonMode] = useState(false);
  const [primaryData, setPrimaryData] = useState(null);
  const [secondaryData, setSecondaryData] = useState(null);
  const [showErrorContent, setShowErrorContent] = useState(false);
  const [isHotspotClickDisabled, setIsHotspotClickDisabled] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState({
    dateRange: returnDefaultDateRange(),
    clientId: useSelector((state) => state.user.currentClientId),
    useCase: useSelector((state) => state.user.currentUseCase),
    environment: useSelector((state) => state.user.currentEnvironment),
    appId: '',
    workflowId: ''
  });
  const [comparisonFilters, setComparisonFilters] = useState({
    dateRange: returnDefaultDateRange(),
    clientId: useSelector((state) => state.user.currentClientId),
    useCase: useSelector((state) => state.user.currentUseCase),
    environment: useSelector((state) => state.user.currentEnvironment),
    appId: '',
    workflowId: ''
  });

  const chartDivRef = useRef();

  const toggleComparisonMode = () => setComparisonMode((prev) => !prev);

  const reset = () => {
    setNoData(true);
    setManualReviewFlagsData([]);
    setAutoRejectedFlagsData([]);
    setErrorFlagsData([]);
    setPrimaryData(null);
    setSecondaryData(null);
    setKeyMetrics({
      autoApprovalRate: '-',
      automationRate: '-',
      conversionRate: '-',
      medianTimeSpent: '-'
    });
    if (chartDivRef && chartDivRef.current) chartDivRef.current.innerHTML = '';
  };

  const computeKeyMetrics = (data) => {
    const { conversion, autoApproval, automation } = calculateKeyMetrics(data);
    setKeyMetrics((prev) => {
      return {
        ...prev,
        conversionRate: conversion,
        autoApprovalRate: autoApproval,
        automationRate: automation
      };
    });
  };

  const drawSankeyDiagram = (data) => {
    if (!data || data.unique === 0) {
      setNoData(true);
      return;
    }

    const percentages = computePercentagesForFunnel(data);
    const weightObj = getSankeyWeights(data);
    const nodes = Object.keys(SANKEY_LABELS).map((key) => {
      return SANKEY_LABELS[key];
    });

    const links = getSankeyLinks(data, weightObj);
    if (links.length === 0) {
      return;
    }

    setNoData(false);

    const analyicsDataDiv = document.querySelector('.analytics-data-div');
    const sankey = SankeyChart(
      { nodes, links, weightObj, percentages },
      { height: 360, width: analyicsDataDiv.clientWidth - 176 }
    );
    if (chartDivRef.current.childNodes.length > 1) {
      chartDivRef.current.removeChild(chartDivRef.current.children[1]);
    }

    chartDivRef.current.appendChild(sankey);

    while (chartDivRef?.current?.childNodes?.length > 1) {
      chartDivRef.current.removeChild(chartDivRef.current.children[1]);
    }
  };

  const getSankeyData = async () => {
    const startTime = performance.now();
    const eventData = {
      APIEndpoint: ANALYTICS_API_ENDPOINTS.BUSINESS_METRICS,
      userClientId: defaultFilters.clientId,
      metaData: {
        filters: defaultFilters
      },
      timestamp: new Date().toISOString()
    };
    setLoadingSankey(true);
    setShowErrorContent(false);
    try {
      const appIds = [defaultFilters.appId];
      const analyticsData = await getAnalytics(
        appIds,
        defaultFilters?.dateRange,
        defaultFilters?.workflowId
      );

      if (isAPIError(analyticsData)) {
        setShowErrorContent(true);
        return;
      }

      setAnalyticsData(analyticsData);
      drawSankeyDiagram(analyticsData);
      computeKeyMetrics(analyticsData);
      set(eventData, 'statusCode', 200);
    } catch (error) {
      Sentry.captureException(error);
      set(eventData, 'statusCode', 500);
    }
    setLoadingSankey(false);
    const endTime = performance.now();
    const processingTime = endTime - startTime;
    set(eventData, 'processingTime', processingTime);
    storeAnalyticsMetadata(
      ANALYTICS_TRACKING_EVENT_NAMES.BUSINESS_METRICS_FUNNEL_API_CALL,
      '',
      AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME,
      '',
      eventData
    );
  };

  const getFlagsData = async () => {
    const startTime = performance.now();
    const eventData = {
      APIEndpoint: ANALYTICS_API_ENDPOINTS.BUSINESS_METRICS_FLAGS,
      userClientId: defaultFilters.clientId,
      metaData: {
        filters: defaultFilters
      },
      timestamp: new Date().toISOString()
    };
    setLoadingFlags(true);
    setShowErrorContent(false);
    try {
      const appIds = [defaultFilters.appId];
      let workflow;
      if (defaultFilters?.workflowId && defaultFilters?.workflowId !== 'All Workflows') {
        const res = await getWorkflowV2({
          appId: defaultFilters?.appId,
          workflowId: defaultFilters?.workflowId
        });
        workflow = res.workflow;
      }
      const flagAnalyticsData = await getFlagAnalytics(
        appIds,
        defaultFilters?.dateRange,
        defaultFilters?.workflowId
      );

      if (isAPIError(flagAnalyticsData)) {
        setShowErrorContent(true);
        return;
      }

      setManualReviewFlagsData(generateFlagsData(flagAnalyticsData?.manualReview, workflow));
      setAutoRejectedFlagsData(generateFlagsData(flagAnalyticsData?.autoRejected, workflow));
      setErrorFlagsData(generateFlagsData(flagAnalyticsData?.error, workflow));
      const medianTime = parseInt(flagAnalyticsData?.medianTime, 10) || 0;
      setKeyMetrics((prev) => ({
        ...prev,
        medianTimeSpent: convertSecondsToMinutesSeconds(medianTime / 1000)
      }));
      set(eventData, 'statusCode', 200);
    } catch (error) {
      Sentry.captureException(error);
      set(eventData, 'statusCode', 500);
    }
    setLoadingFlags(false);
    const endTime = performance.now();
    const processingTime = endTime - startTime;
    set(eventData, 'processingTime', processingTime);
    storeAnalyticsMetadata(
      ANALYTICS_TRACKING_EVENT_NAMES.BUSINESS_METRICS_FLAGS_API_CALL,
      '',
      AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME,
      '',
      eventData
    );
  };

  const getAnalyticsData = () => {
    reset();
    if (!defaultFilters.appId || !defaultFilters.dateRange) return;
    if (comparisonMode) {
      return;
    }

    getSankeyData();
    getFlagsData();
  };

  const { ref } = useResizeDetector({
    onResize: () => {
      drawSankeyDiagram(analyticsData);
    }
  });

  const onFiltersApply = async () => {
    setFunnelFirstLoad(false);
    await getAnalyticsData();
  };

  const getComparisonData = async () => {
    const startTime = performance.now();
    const eventData = {
      APIEndpoint: ANALYTICS_API_ENDPOINTS.BUSINESS_METRICS_FLAGS,
      userClientId: defaultFilters.clientId,
      metaData: {
        filters: defaultFilters
      },
      timestamp: new Date().toISOString()
    };
    setLoadingComparisons(true);
    setShowErrorContent(false);
    try {
      const primaryDataRes = await getAnalytics(
        [defaultFilters.appId],
        defaultFilters.dateRange,
        defaultFilters.workflowId
      );
      const secondaryDataRes = await getAnalytics(
        [comparisonFilters.appId],
        comparisonFilters.dateRange,
        comparisonFilters.workflowId
      );

      if (isAPIError(primaryDataRes) || isAPIError(secondaryDataRes)) {
        setShowErrorContent(true);
        return;
      }

      setPrimaryData(primaryDataRes);
      setSecondaryData(secondaryDataRes);
      set(eventData, 'statusCode', 200);
    } catch (error) {
      Sentry.captureException(error);
      set(eventData, 'statusCode', 500);
    }
    setLoadingComparisons(false);
    const endTime = performance.now();
    const processingTime = endTime - startTime;
    set(eventData, 'processingTime', processingTime);
    storeAnalyticsMetadata(
      ANALYTICS_TRACKING_EVENT_NAMES.BUSINESS_METRICS_FLAGS_API_CALL,
      '',
      AUDIT_PORTAL_PERFORMANCE_MONITORING_TABLE_NAME,
      '',
      eventData
    );
  };

  const onComparisonApply = async () => {
    setComparisonsFirstLoad(false);
    await getComparisonData();
  };

  useEffect(() => {
    storeAnalyticsMetadata(
      ANALYTICS_TRACKING_EVENT_NAMES.BUSINESS_METRICS_PAGE_LOAD,
      ANALYTICS_TRACKING_SCREEN_NAMES.BUSINESS_METRICS
    );
  }, []);

  useEffect(() => {
    reset();
  }, [comparisonMode]);

  useEffect(() => {
    setIsHotspotClickDisabled(defaultFilters?.workflowId === 'All Workflows');
  }, [defaultFilters?.workflowId]);

  return (
    <div className="home-parent-div">
      <div role="presentation" className="home-content-div">
        <div className="funnel-analytics-header-container">
          <div className="funnel-analytics-header">
            <h3>Business Metrics</h3>
            <Toggle
              label="Comparison Mode"
              initValue={comparisonMode}
              onToggle={toggleComparisonMode}
            />
          </div>
          <AnalyticsFilters
            isLoading={loadingSankey || loadingFlags}
            filters={defaultFilters}
            updateFilters={setDefaultFilters}
            dispatchUpdates={false}
            showAllWorkflowsOption
            onFiltersApply={onFiltersApply}
            displayApplyButton={!comparisonMode}
            displayRefreshButton={!comparisonMode}
          />
          {comparisonMode && (
            <AnalyticsFilters
              isLoading={loadingComparisons}
              filters={comparisonFilters}
              updateFilters={setComparisonFilters}
              secondaryFilters={defaultFilters}
              dispatchUpdates={false}
              showAllWorkflowsOption
              onFiltersApply={onComparisonApply}
              displayRefreshButton
            />
          )}
        </div>
        <div className="funnel-analytics-content-container">
          {comparisonMode ? (
            <FunnelAnalyticsComparison
              primaryData={primaryData}
              secondaryData={secondaryData}
              isLoading={loadingComparisons}
              firstLoad={comparisonsFirstLoad}
              showErrorContent={showErrorContent}
              onComparisonApply={onComparisonApply}
            />
          ) : (
            <FunnelAnalytics
              ref={ref}
              chartDivRef={chartDivRef}
              loadingSankey={loadingSankey}
              loadingFlags={loadingFlags}
              noData={noData}
              conversionRate={keyMetrics.conversionRate}
              autoapprovalRate={keyMetrics.autoApprovalRate}
              automationRate={keyMetrics.automationRate}
              medianTimeSpent={keyMetrics.medianTimeSpent}
              manualReviewFlagsData={manualReviewFlagsData}
              autoRejectedFlagsData={autoRejectedFlagsData}
              errorFlagsData={errorFlagsData}
              firstLoad={funnelFirstLoad}
              showErrorContent={showErrorContent}
              onFiltersApply={onFiltersApply}
              filters={defaultFilters}
              isHotspotClickDisabled={isHotspotClickDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PermissionWrapper(
  CustomConnect(BusinessMetrics, { mapHooksToActions: {} }),
  useGetUserPermissions,
  getPermission('businessMetrics')
);
