export const ANALYTICS_TRACKING_EVENT_NAMES = {
  STEPWISE_PAGE_LOAD: 'Stepwise Analytics Page Load',
  BUSINESS_METRICS_PAGE_LOAD: 'Business Metrics Page Load',
  BUSINESS_METRICS_COMPARISONS_PAGE_LOAD: 'Business Metrics Comparison Page Load',
  BUSINESS_METRICS_FUNNEL_API_CALL: 'Business Metrics Funnel API Call',
  BUSINESS_METRICS_COMPARISONS_API_CALL: 'Business Metrics Comparison API Call',
  STEPWISE_API_CALL: 'Stepwise Analytics API Call',
  STEPWISE_DROP_OFFS_API_CALL: 'Stepwise Drop Offs API Call',
  BUSINESS_METRICS_FLAGS_API_CALL: 'Business Metrics Flags API Call',
  BUSINESS_METRICS_HOTSPOTS_API_CALL: 'Business Metrics Hotspots API Call'
};

export const ANALYTICS_TRACKING_SCREEN_NAMES = {
  STEPWISE_ANALYTICS: 'Stepwise Analytics',
  BUSINESS_METRICS: 'Business Metrics',
  BUSINESS_METRICS_COMPARSIONS: 'Business Metrics Comparison'
};
